<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <searchBasic :search="search" @search-data="searchIt"/>
            <b-col lg="12" sm="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </template>
      </iq-card>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.list') }}</h4>
                  </template>
                  <template v-slot:body>
                      <b-overlay :show="loadingState">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <router-link title="View" :to="`/training-e-learning-service/iabm/invitation?circular_memo_no=${data.item.circular_memo_no}&batch_no=${data.item.batch_no}`" class="btn_table_action table_action_view"><i class="fas fa-eye"></i></router-link>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                      </b-overlay>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
      <b-modal id="modal-4" size="lg" :title="$t('elearning_iabm.lesson_assign_circular_wise') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
      </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { invitationCircularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'
import Store from '@/store'
import Details from './Details'
import searchBasic from './../../../component/searchBasic'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details,
    searchBasic
  },
  data () {
      return {
        circularLoading: false,
        search: {
          coordinator_id: 0,
          org_id: null,
          fiscal_year_id: null,
          circular_memo_no: 0,
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          batch_no: 0,
          course_module_id: null
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainerNameList: [],
        trainerList: [],
        circularList: [],
        allBatchListData: [],
        trainingTypeList: [],
        batchList: [],
        courseModuleList: [],
        myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
      }
  },
  computed: {
     allBatchList () {
      const batchList = [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
      return batchList
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
            { label: this.$t('elearning_config.organization'), class: 'text-center' },
            { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
            { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
            { label: this.$t('elearning_config.training_type'), class: 'text-center' },
            { label: this.$t('elearning_config.training_category'), class: 'text-center' },
            { label: this.$t('elearning_config.training_title'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_name_bn' },
              { key: 'circular_memo_no' },
              { key: 'batch_name_bn' },
              { key: 'training_type_bn' },
              { key: 'training_category_bn' },
              { key: 'training_title_bn' },
              { key: 'action' }
            ]
        } else {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org_name' },
              { key: 'circular_memo_no' },
              { key: 'batch_name' },
              { key: 'training_type' },
              { key: 'training_category' },
              { key: 'training_title' },
              { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  async created () {
    this.search.fiscal_year_id = this.$store.state.TrainingElearning.currentFiscalYearId
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
    this.loadData()
  },
  mounted () {
      core.index()
  },
  watch: {
  },
  methods: {
    searchIt (search) {
      this.search = search
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    edit (item) {
      this.editItemId = item.id
    },
    async searchData () {
      this.loadData()
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, invitationCircularList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgNameData = {}
        if (typeof orgObj !== 'undefined') {
          orgNameData.org_name = orgObj.text_en
          orgNameData.org_name_bn = orgObj.text_bn
        } else {
          orgNameData.org_name = ''
          orgNameData.org_name_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        const OfficeTypeData = {}
        if (typeof OfficeTypeListObj !== 'undefined') {
          OfficeTypeData.office_type = OfficeTypeListObj.text_en
          OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          OfficeTypeData.office_type = ''
          OfficeTypeData.office_type_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
        const batchData = {}
        if (typeof batchObj !== 'undefined') {
          batchData.batch_name = batchObj.text_en
          batchData.batch_name_bn = batchObj.text_bn
        } else {
          batchData.batch_name = ''
          batchData.batch_name_bn = ''
        }
        return Object.assign({}, item, fiscalYearData, orgNameData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, batchData)
      })
      return listData
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
